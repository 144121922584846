import { FormArray, FormControl, FormGroup } from "@angular/forms";

import {
  describeRegex,
  MessageNode,
  ValidatorTuple,
  ValidatorValue,
} from "./validators";

export type ToForm<T> = {
  [K in keyof T]: T[K] extends Array<infer U>
    ? U extends object
      ? FormArray<FormGroup<ToForm<U>>>
      : FormControl<U[] | null>
    : T[K] extends Date
    ? FormControl<Date | null>
    : T[K] extends object
    ? FormGroup<ToForm<T[K]>>
    : FormControl<T[K] | null>;
};

export const makeErrorMessage = (
  error: ValidatorTuple,
  fieldName = "This field"
) => {
  const [validator, value] = error;
  let message: string | MessageNode = "";

  // console.log(validator, value);
  switch (validator) {
    case "required":
      message = `${fieldName} is required`;
      break;

    case "minlength":
      const { requiredLength, actualLength } =
        value as ValidatorValue<"minlength">;
      message = `${fieldName} should be at least ${requiredLength} characters. (currently: ${actualLength})`;

      break;
    case "maxlength":
      {
        const { requiredLength, actualLength } =
          value as ValidatorValue<"maxlength">;
        message = `${fieldName} should not exceed ${requiredLength} characters. (currently: ${actualLength})`;
      }
      break;
    case "pattern":
      message = describeRegex(
        fieldName,
        (value as ValidatorValue<"pattern">)["requiredPattern"],
        (value as ValidatorValue<"pattern">)["actualValue"]
      ).title;

      break;
    case "min":
      {
        const { min, actual } = value as ValidatorValue<"min">;
        message = `${fieldName} should be at least ${min} (currently: ${actual})`;
      }
      break;

    case "max":
      {
        const { max, actual } = value as ValidatorValue<"max">;
        message = `${fieldName} should not exceed ${max} (currently: ${actual})`;
      }
      break;

    default:
      //  message = `${error[0]} should be ${validator}. (currently: ${validatorValue})`;
      break;
  }

  return message;
};
