import { AbstractControl, ValidatorFn } from "@angular/forms";

export type Validators = {
  required: boolean;
  minlength: { requiredLength: number; actualLength: number };
  maxlength: { requiredLength: number; actualLength: number };
  pattern: { requiredPattern: string; actualValue: string };
  min: {min: number, actual: number}
  max: {max: number, actual: number}
};

export type ValidatorTuple = [keyof Validators, Validators[keyof Validators]];

export type ValidatorValue<T extends keyof Validators> = Validators[T];

export type MessageNode = {
  title: string;
  children?: MessageNode[];
};

// export const validateAndDescribeRegex = (
//   value: string,
//   pattern: string
// ): string => {
//   const regex = new RegExp(pattern);
//   const isMatch = regex.test(value);

//   if (isMatch) {
//     return "The value matches the pattern.";
//   }

//   let feedback = "";
//   const errors: Set<string> = new Set();

//   const regexParts: string[] =
//     pattern.match(/(\^|\$|\[.*?\]|\+|\*|\?|\.)/g) || [];
//   for (const part of regexParts) {
//     switch (part) {
//       case CustomRegex.arabicEnglishNumbersLetters:
//         if (
//           new RegExp(
//             `[^${CustomRegex.arabicEnglishNumbersLetters.slice(1, -1)}]`
//           ).test(value)
//         ) {
//           errors.add(
//             "contains characters that are not Arabic or English alphanumeric. (e.g: Sol123)"
//           );
//         }
//         break;
//       case CustomRegex.arabicEnglishLetters:
//         if (
//           new RegExp(
//             `[^${CustomRegex.arabicEnglishLetters.slice(1, -1)}]`
//           ).test(value)
//         ) {
//           errors.add(
//             "contains characters that are not Arabic or English letters."
//           );
//         }
//         break;
//       case CustomRegex.arabicEnglishNumbers:
//         if (
//           new RegExp(
//             `[^${CustomRegex.arabicEnglishNumbers.slice(1, -1)}]`
//           ).test(value)
//         ) {
//           errors.add(
//             "contains characters that are not Arabic or English numbers."
//           );
//         }
//         break;
//       case CustomRegex.englishNumbersLetters:
//         if (
//           new RegExp(
//             `[^${CustomRegex.englishNumbersLetters.slice(1, -1)}]`
//           ).test(value)
//         ) {
//           errors.add("contains characters that are not English alphanumeric.");
//         }
//         break;
//       case CustomRegex.arabicNumbersLetters:
//         if (
//           new RegExp(
//             `[^${CustomRegex.arabicNumbersLetters.slice(1, -1)}]`
//           ).test(value)
//         ) {
//           errors.add(
//             "contains characters that are not Arabic letters or English alphanumeric."
//           );
//         }
//         break;
//       case CustomRegex.englishLetters:
//         if (
//           new RegExp(`[^${CustomRegex.englishLetters.slice(1, -1)}]`).test(
//             value
//           )
//         ) {
//           errors.add("contains characters that are not English letters.");
//         }
//         break;
//       case CustomRegex.englishNumbers:
//         if (
//           new RegExp(`[^${CustomRegex.englishNumbers.slice(1, -1)}]`).test(
//             value
//           )
//         ) {
//           errors.add("contains characters that are not English numbers.");
//         }
//         break;
//       case CustomRegex.arabicLetters:
//         if (
//           new RegExp(`[^${CustomRegex.arabicLetters.slice(1, -1)}]`).test(value)
//         ) {
//           errors.add("contains characters that are not Arabic letters.");
//         }
//         break;
//       case CustomRegex.arabicNumbers:
//         if (
//           new RegExp(`[^${CustomRegex.arabicNumbers.slice(1, -1)}]`).test(value)
//         ) {
//           errors.add("contains characters that are not Arabic numbers.");
//         }
//         break;
//       case ".":
//         break;
//       case "+":
//         const precedingPart = regexParts[regexParts.indexOf(part) - 1];
//         if (!new RegExp(precedingPart + "+").test(value)) {
//           errors.add(`does not contain one or more ${precedingPart}.`);
//         }
//         break;
//       case "*":
//         break;
//       case "?":
//         break;
//       default:
//         errors.add("has invalid pattern");
//         break;
//     }
//   }

//   // Filter out redundant messages
//   if (
//     errors.has(
//       "contains characters that are not Arabic or English alphanumeric. (e.g: Sol123)"
//     )
//   ) {
//     errors.delete(
//       "contains characters that are not Arabic or English letters."
//     );
//     errors.delete(
//       "contains characters that are not Arabic or English numbers."
//     );
//   }

//   feedback += [...errors].join("\n");
//   return feedback;
// };

export const describeRegex = (
  fieldName: string,
  pattern: string,
  value: string
): MessageNode => {
  console.log(fieldName, pattern, value)
  return {
    title: `${fieldName} has invalid pattern`,
  };
};

export const NoWhiteSpaceValidatior: ValidatorFn = (control: AbstractControl) => {
  if (control.value === null) return null;
  const isWhiteSpace = (control?.value ?? "").trim().length === 0;
  return isWhiteSpace ? { required: true } : null;
};