export type InputType =
  | "text"
  | "price"
  | "number"
  | "password"
  | "date"
  | "email"
  | "percentage"
  | "url"
  | "textarea"
  | "file"
  | "radio"
  | "numberedText"

export type NumberedInputType = "number" | "percentage" | "price" | "numberedText";

export type InputOptions = Partial<{
  disabled: boolean;
  decimalPoints: number;
  withDecimals: boolean;
  withIcon: boolean;
  allowNegative: boolean;
  allowDecimals: boolean;
  minLength:number;
  maxLength:number;
  label: string;
  max: number | null;
  min: number | null;
  rows: number;
}> 

export type InternalInputOptions = InputOptions &
  Partial<{
    types: Partial<Record<InputType, InputOptions>>;
  }>;

export const defaultInputSettings:Required<InternalInputOptions> = {
  allowNegative: false,
  allowDecimals: false,
  withIcon: true,
  disabled: false,
  decimalPoints: 2,
  maxLength: 100,
  minLength:0,
  min:null,
  max:null,
  rows:3,
  label:"",
  withDecimals: true,
  types: {
    // file:{},
    // text: {},
    // date: {},
    // email: {},
    // password: {},
    // url: {},
    // textarea: {
    // },
    // radio:{},
    price: {
      allowDecimals: true,
    },
    percentage: {
      allowDecimals: true,
      min: 0,
      max: 100
    },
    number: {
      allowDecimals: true,
      allowNegative: true
    },
    numberedText: {
      allowDecimals: false,
      allowNegative: false
    },
  },
};

export type InputValue = number | string | Date;
