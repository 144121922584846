import { Component, inject, Input, OnInit } from "@angular/core";
import { makeErrorMessage } from "./form.util";
import { ValidatorTuple } from "./validators";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { CommonModule, NgIf } from "@angular/common";
import { FormControlDirective } from "@coreui/angular-pro";
import { FormFeedbackComponent } from "@coreui/angular";
import { overrideObj } from "../object.util";
import { CRenderDirective } from "../../directives/c-render/c-render.directive";

type FormControlOptions<T> = { label?: string } & T;

export const CustomFormControlModules = [
  CommonModule,
  NgIf,
  FormControlDirective,
  ReactiveFormsModule,
  FormFeedbackComponent,
  CRenderDirective
];

@Component({
  standalone: true,
  imports: CustomFormControlModules,
  template: "",
})
abstract class CustomFormControl<BaseOptions, Settings extends BaseOptions>
  implements OnInit
{
  @Input() options?: FormControlOptions<BaseOptions>;

  internalOptions: FormControlOptions<Settings>;
  cRender: CRenderDirective | null = inject(CRenderDirective, { optional: true });
  constructor() {}
  ngOnInit(): void {
    this.internalOptions = overrideObj(
      {
        ...this.defaultSettings,
      } as FormControlOptions<BaseOptions>,
      {...this.options,
        label: "something",} as FormControlOptions<Settings>
    );
    this.cRender?.addControl(this.control);
  }
  
  @Input() control: FormControl;
  abstract defaultSettings: BaseOptions;

  get controlErrors() {
    return Object.entries(this.control?.errors || {}) as ValidatorTuple[];
  }

  get controlTopError() {
    return Object.entries(this.control?.errors || {})[0] as ValidatorTuple;
  }

  displayError(error: ValidatorTuple) {
    return makeErrorMessage(error, this.options?.label);
  }
}

export default CustomFormControl;
