import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import {
	FormCheckComponent,
	FormCheckInputDirective,
	FormCheckLabelDirective,
	FormControlDirective,
} from "@coreui/angular-pro";
import { InputValue } from "../input/input.model";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { CRenderDirective } from "../../../shared/directives/c-render/c-render.directive";

@Component({
	selector: "sol-checkbox",
	standalone: true,
	imports: [
		FormCheckComponent,
		FormCheckLabelDirective,
		FormCheckInputDirective,
		FormControlDirective,
		ReactiveFormsModule,
	],
	templateUrl: "./checkbox.component.html",
	styleUrl: "./checkbox.component.scss",
})
export class CheckboxComponent implements OnInit {
	@Input() label: string;
	@Input() isChecked?: boolean;
	@Input() disabled: boolean;
	@Input() control?: FormControl;
	@Input() direction: "horizontal" | "vertical" = "horizontal";
	@Output() onChange = new EventEmitter<boolean>();
	labelStyle: string = "";
	cRender: CRenderDirective | null = inject(CRenderDirective, { optional: true });

	ngOnInit(): void {
		this.labelStyle = this.direction === 'vertical' ? 'opacity:  1;' : ''
		if (this.control) {
			this.cRender?.addControl(this.control);

		}
	}

	updateValue(event: any) {
		const value = event.target.checked as boolean;
		this.onChange.emit(value);
	}
}
