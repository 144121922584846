export function overrideObj<T extends object, U extends Partial<T>>(
  base: T,
  extension: U
): T & U {
  const result = { ...base } as T & U; // Start with a shallow copy of the base object

  for (const key in extension) {
    if (Object.prototype.hasOwnProperty.call(extension, key)) {
      const baseValue = (base as any)[key]; // Value from the base object
      const extensionValue = (extension as any)[key]; // Value from the extension object

      // If both base and extension values are objects, perform a deep merge
      if (isObject(baseValue) && isObject(extensionValue)) {
        result[key] = overrideObj(baseValue, extensionValue) as any; // Recursive merge for nested objects
      } else {
        result[key] = extensionValue; // Otherwise, override the base value with the extension value
      }
    }
  }

  return result;
}

// Helper function to check if a value is an object (and not null)
function isObject(value: any): value is object {
  return value && typeof value === "object" && !Array.isArray(value);
}

